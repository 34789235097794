/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.ngx-gallery-close {
  transform: translateY(8vh) !important;
}

::ng-deep .mat-focused {
  color: pink !important;
}